.Project{
    background-color: #ffffff;
    color:#d2ede0;
    z-index: 1;
}

.About{
    background-color: #03cefc;
    z-index: 1;
}

.roundimg {
    border-radius: 50%;
  }

.particles {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.Last{
    z-index: 2;
}